/* global Cookies */

/**
 * EventTracker is frontend wrapper for TrackingEvent model
 */
class EventTracker {
    constructor(url, category, action, viewedUUID) {
        this.url = url;
        this.category = category;
        this.action = action;
        this.viewedUUID = viewedUUID;
    }

    _trackEvent(data) {
        return $.ajax({
            url: this.url,
            type: 'POST',
            headers: { 'X-CSRFToken': Cookies.get('csrftoken') },
            data,
        });
    }

    trackRelatedContentClick({
        itemIndex,
        tags,
        pathSelector,
        abTestName,
        abTestVariant,
        isLsContent,
        maxLsIndex,
        learningJourneyGroup,
        learningJourneyItemIndex,
    }) {
        const eventData = {
            category: this.category.RELATED_CONTENT,
            action: this.action.RELATED_CONTENT_CLICK,
            viewed_uuid: this.viewedUUID,
            index: itemIndex,
            tags,
            path_selector: pathSelector,
            ab_test_name: abTestName,
            ab_test_variant: abTestVariant,
            is_ls_content: isLsContent,
            max_ls_index: maxLsIndex,
            learning_journey_group: learningJourneyGroup,
            learning_journey_item_index: learningJourneyItemIndex,
        };
        this._trackEvent(eventData);
    }

    trackFullscreenOn() {
        const data = {
            category: this.category.VIDEO_PLAYER,
            action: this.action.FULLSCREEN_ON,
            viewed_uuid: this.viewedUUID,
        };
        this._trackEvent(data);
    }

    trackFullscreenOff() {
        const data = {
            category: this.category.VIDEO_PLAYER,
            action: this.action.FULLSCREEN_OFF,
            viewed_uuid: this.viewedUUID,
        };
        this._trackEvent(data);
    }

    trackOrientationPortrait() {
        const data = {
            category: this.category.PAGE_ORIENTATION,
            action: this.action.ORIENTATION_PORTRAIT,
            viewed_uuid: this.viewedUUID,
        };
        this._trackEvent(data);
    }

    trackOrientationLandscape() {
        const data = {
            category: this.category.PAGE_ORIENTATION,
            action: this.action.ORIENTATION_LANDSCAPE,
            viewed_uuid: this.viewedUUID,
        };
        this._trackEvent(data);
    }

    trackSmartBannerDisplay() {
        const data = {
            category: this.category.SMART_BANNER,
            action: this.action.DISPLAY,
            viewed_uuid: this.viewedUUID,
        };
        this._trackEvent(data);
    }

    trackSmartBannerClick() {
        const data = {
            category: this.category.SMART_BANNER,
            action: this.action.CLICK,
            viewed_uuid: this.viewedUUID,
        };
        return this._trackEvent(data);
    }

    trackSmartBannerDismiss() {
        const data = {
            category: this.category.SMART_BANNER,
            action: this.action.DISMISS,
            viewed_uuid: this.viewedUUID,
        };
        this._trackEvent(data);
    }

    trackTapToUnmute(source, eventTimestamp) {
        const data = {
            category: this.category.VIDEO_PLAYER,
            action: this.action.PLAYER_UNMUTED,
            viewed_uuid: this.viewedUUID,
            video_timestamp: eventTimestamp,
            source,
        };
        this._trackEvent(data);
    }

    trackPlaybackRateChange(playbackRate, eventTimestamp) {
        const data = {
            category: this.category.VIDEO_PLAYER,
            action: this.action.PLAYBACK_RATE,
            viewed_uuid: this.viewedUUID,
            playback_rate: playbackRate,
            video_timestamp: eventTimestamp,
        };
        this._trackEvent(data);
    }

    trackVideoSeek(source, direction, eventTimestamp) {
        this._trackEvent({
            category: this.category.VIDEO_PLAYER,
            action: this.action.SEEK_TRIGGERED,
            viewed_uuid: this.viewedUUID,
            video_timestamp: eventTimestamp,
            direction,
            source,
        });
    }

    trackChapterListToggle(videoTimestamp, state, source) {
        this._trackEvent({
            category: this.category.VIDEO_PLAYER,
            action: this.action.CHAPTERS_TOGGLED,
            viewed_uuid: this.viewedUUID,
            video_timestamp: videoTimestamp,
            state,
            source,
        });
    }

    trackChapterClick(videoTimestamp, chapterNumber, chapterHeading, chapterTimestamp) {
        this._trackEvent({
            category: this.category.VIDEO_PLAYER,
            action: this.action.CHAPTER_CLICKED,
            viewed_uuid: this.viewedUUID,
            video_timestamp: videoTimestamp,
            chapter_number: chapterNumber,
            chapter_heading: chapterHeading,
            chapter_timestamp: chapterTimestamp,
        });
    }

    trackHomefeedShowMore() {
        this._trackEvent({
            category: this.category.HOMEFEED,
            action: this.action.SHOW_MORE_CLICK,
        });
    }

    trackHomefeedRecommended(videoId, videoPosition, isCampaign) {
        this._trackEvent({
            category: this.category.HOMEFEED,
            action: this.action.RECOMMENDED_CONTENT_CLICK,
            video_id: videoId,
            video_position: videoPosition,
            is_campaign: isCampaign,
        });
    }
}

export default EventTracker;
